/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/01/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TabOption } from "./TabOption";

const Accordion = ({
  title,
  children,
  show,
  subtitle,
  keepExpanded,
  defaultExpanded,
}) => {
  const onTabClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  return (
    <div
      className={classnames(
        "card-box",
        show ? "d-display" : "d-none",
        "sidebar-panel-box sidebar-menu widget-settings-tab"
      )}
    >
      <ul>
        <li className="submenu">
          <a
            className={classnames("align-items-baseline", {
              subdrop: defaultExpanded,
            })}
            href={keepExpanded ? null : "#"}
            onClick={onTabClick}
          >
            <div className={"d-flex flex-column"}>
              <h4>{title}</h4>
              <h6>{subtitle}</h6>
            </div>

            {!keepExpanded && <span className="menu-arrow" />}
          </a>
          <ul
            className="list-unstyled"
            style={{
              display: keepExpanded || defaultExpanded ? "block" : "none",
            }}
          >
            {children}
          </ul>
        </li>
      </ul>
    </div>
  );
};

Accordion.defaultProps = {
  show: true,
  subtitle: null,
};

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  show: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  keepExpanded: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
};

export default Accordion;
