/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/01/2024
 */

import React from "react";
import { QRCode as QRComponent } from "../../../../WidgetsComponents/SharePopup/QRCode";

// This function returns a Promise whenever the $img is loaded

const QRCode = ({ url, title, subtitle = "QR Code" }) => {
  /**
   *
   * @type {(function(): void)|*}
   */

  return (
    <div className="form-group ">
      <h5>{subtitle}</h5>
      <QRComponent
        value={url}
        title={title}
        showDownloadButton={true}
        containerClassName={"dc-share-popup-install-tab"}
        showQrCode={true}
        qrCodeBgColor={"#fff"}
        qrCodeFgColor={"#000"}
      />
      {/*<QRComponent value={value} ref={qrCodeRef} />*/}
    </div>
  );
};

export default QRCode;
