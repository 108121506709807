export const headerTemplate = (settings) => {
  return `
  <!--[if mso]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:PixelsPerInch>96</o:PixelsPerInch>
      <o:AllowPNG/>
    </o:OfficeDocumentSettings>
  </xml><![endif]-->
  <style>
    * {
      box-sizing: border-box
    }


    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none
    }

    p {
      line-height: inherit
    }

    .desktop_hide, .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0;
      overflow: hidden
    }

    .image_block img + div {
      display: none
    }

    sub, sup {
      line-height: 0;
      font-size: 75%
    }

    @media (max-width: 520px) {
      .mobile_hide {
        display: none
      }

      .row-content {
        width: 100% !important
      }

      .stack .column {
        width: 100%;
        display: block
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0
      }

      .desktop_hide, .desktop_hide table {
        display: table !important;
        max-height: none !important
      }
    }
  </style>
  <!--[if mso ]>
  <style>sup, sub {
    font-size: 100% !important;
  }

  sup {
    mso-text-raise: 10%
  }

  sub {
    mso-text-raise: -10%
  }</style> <![endif]-->`;
};
export const itemTemplate = (settings) => {
  const image = settings.showImage
    ? `<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad" style="padding-bottom:10px;width:100%">
                        <div
                          class="alignment" align="center" style="line-height:10px">
                          <div style="max-width:500px"><a href="*|FEEDITEM:URL|*" target="_blank" style="outline:none"
                                                          tabindex="-1">*|FEEDITEM:IMAGE|*</a></div>
                        </div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const title = settings.showTitle
    ? `<table class="heading_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad" style="text-align:center;width:100%"><h1
                        style="margin:0;color:#555;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:23px;font-weight:700;letter-spacing:normal;line-height:120%;text-align:left;margin-top:0;margin-bottom:0;mso-line-height-alt:27.599999999999998px">
                        <span class="tinyMce-placeholder" style="word-break: break-word;">
                        <a href="*|FEEDITEM:URL|*"
                                                                                             target="_blank" class
                                                                                             title="*|FEEDITEM:TITLE|*"
                                                                                             style="text-decoration: underline; color: #0068A5;"
                                                                                             data-segment="1.2"
                                                                                             rel="noopener"><span
                          class="mce-content-body mce-edit-focus" style="word-break: break-word; position: relative;"
                          id="84810cb3-5b31-412e-84df-127c7f000c51" data-position="10-0-0"
                          data-qa="tinyeditor-root-element"><span class="tinyMce-placeholder"
                                                                  style="word-break: break-word;">*|FEEDITEM:TITLE|*</span></span></a></span>
                      </h1></td>
                    </tr>
                  </table>`
    : "";

  const description = settings.showDescription
    ? `<table class="paragraph_block block-4" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                    <tr>
                      <td class="pad" style="padding-bottom:10px;padding-right:10px;padding-top:10px">
                        <div
                          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
                          <p style="margin:0">*|FEEDITEM:CONTENT|*</p></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const readMore = settings.showReadMore
    ? `<table class="button_block block-5"
                         width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
                         style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad" style="text-align:left">
                        <div class="alignment" align="left"><a href="*|FEEDITEM:URL|*" target="_blank"><!--[if mso]>
                          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml"
                                       xmlns:w="urn:schemas-microsoft-com:office:word" href="*|FEEDITEM:URL|*"
                                       style="height:38px;width:109px;v-text-anchor:middle;" arcsize="11%"
                                       fillcolor="#3AAEE0">
                            <v:stroke dashstyle="Solid" weight="0px" color="#3AAEE0"/>
                            <w:anchorlock/>
                            <v:textbox inset="0px,0px,0px,0px">
                              <center dir="false" style="color:#ffffff;font-family:sans-serif;font-size:14px">
                          <![endif]-->
                          <div
                            style="background-color:#3aaee0;border-bottom:0 solid transparent;border-left:0 solid transparent;border-radius:4px;border-right:0 solid transparent;border-top:0 solid transparent;color:#fff;display:inline-block;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;mso-border-alt:none;padding-bottom:5px;padding-top:5px;text-align:center;text-decoration:none;width:auto;word-break:keep-all">
                            <span
                              style="word-break: break-word; padding-left: 20px; padding-right: 20px; font-size: 14px; display: inline-block; letter-spacing: normal;"><span
                              style="word-break: break-word; line-height: 28px;">Read more</span></span></div>
                          <!--[if mso]></center></v:textbox></v:roundrect><![endif]--></a></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const date = settings.showDate ? "*|FEEDITEM:DATE:D,d M Y, g:i A|*" : null;
  const location = settings.showLocation ? "*|FEEDITEM:CATEGORIES|*" : null;
  const dateLocationString = [date, location].filter((i) => i).join(", ");

  const dateLocation = dateLocationString.length
    ? `<table className="paragraph_block block-3" width="100%" border="0" cellPadding="0" cellSpacing="0"
         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
    <tr>
      <td className="pad" style="padding-right:10px;padding-top:10px">
        <div
          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
          <p style="margin:0">${dateLocationString}</p></div>
      </td>
    </tr>
  </table>`
    : "";

  return `
  <div style="background-color:${settings.bgColor};margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none;margin: 0; padding: 0">
<table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
       style="mso-table-lspace:0;mso-table-rspace:0;background-color:${settings.bgColor}">
  <tbody>
  <tr>
    <td>
      <table class="row row-1" align="center"
             width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
             style="mso-table-lspace:0;mso-table-rspace:0">
        <tbody>
        <tr>
          <td>
            <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                   role="presentation"
                   style="mso-table-lspace:0;mso-table-rspace:0;color:#000;width:500px;margin:0 auto" width="500">
              <tbody>
              <tr>
                <td class="column column-1" width="100%"
                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;border-bottom:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-top:0 solid transparent;padding-bottom:5px;padding-top:5px;vertical-align:top">
                  ${image}
                  ${title}
                  ${dateLocation}
                  ${description}
                  ${readMore}
                  <table class="divider_block block-6" width="100%" border="0" cellpadding="10" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad">
                        <div class="alignment" align="center">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                                 style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="divider_inner" style="font-size:1px;line-height:1px;border-top:1px solid #bbb">
                                <span style="word-break: break-word;">&#8202;</span></td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </tbody>
</table><!-- End --></div>`;
};
