/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React, { useMemo } from "react";
import InstallAccordion from "./InstallAccordion";
import ClipboardCopyInput from "../../component/ClipboardCopyInput";
import WebsiteMissingWarning from "./WebsiteMissingWarning";
import WebsitesVerifiedInfo from "./WebsiteVerifiedInfo";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installScripTag,
} from "../../templates/WidgetPageTemplate/InstallTab/helpers";

const alternativeListStyle = { listStyleType: "'🚧 '", paddingLeft: "10px" };
const experimentalListStyle = { listStyleType: "'🛑 '", paddingLeft: "10px" };
const StandardInstall = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const installDiv = useMemo(
    () => getInstallDivTag(widgetType, uuid),
    [widgetType, uuid]
  );
  const installDivIframe = useMemo(
    () => getInstallIframeTag(widgetType, uuid, slug),
    [widgetType, uuid, slug]
  );
  return (
    <div>
      {/*     <div className={"w-100 d-flex align-items-center flex-column"}>
        <p className={"text-center"}>
          Watch our tutorial video below or <a href={"#"}>Read our Article</a>.
        </p>
        <div>
          <iframe
            width="355"
            height="200"
            src="https://www.youtube.com/embed/7c7Wfz5rMn0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>*/}

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={<>Default Installation (Recommended)</>}
        defaultExpanded={false}
        expandable={true}
      >
        <p>
          To display your widget seamlessly on your website, follow these simple
          steps. <strong>Step 1</strong> is a one-time setup that enables your
          website to display your widget . Once you've completed Step 1, you
          only need to repeat <strong>Step 2</strong> to place the widget
          wherever you’d like on your webpage.
        </p>
        <ol>
          <li>
            <strong>Include the Snippet</strong>
            <p>
              Copy and paste the following snippet into the{" "}
              <span className={"text-purple"}>&lt;head&gt;</span> section of
              your website. This snippet loads the necessary scripts for our
              {widgetTypePrintable} functionality:
            </p>
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={"Calendar embeddable script"}
            />
          </li>
          <li>
            <strong>Embed the Calendar</strong>
            <p></p>
            Now that Step 1 is complete, you can easily embed the
            {widgetTypePrintable}. Simply copy the following HTML5 object and
            place it in the location on your webpage where you want the widget
            to appear:
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDiv}
              placeholder={`Calendar embeddable div`}
            />
          </li>
        </ol>
      </InstallAccordion>
      <InstallAccordion
        title={<>Alternative Installation (Limited Website Access)</>}
        defaultExpanded={false}
        expandable={true}
      >
        <p>
          This installation method is intended for users who are unable to
          access the <span className={"text-purple"}>&lt;head&gt;</span> section
          of their website as discussed in the recommended installation.
          However, please note that this approach is not ideal, especially if
          you plan to use multiple {widgetTypePrintable} on the same webpage.
          Using this method repeatedly can negatively impact your website's
          speed and the performance of your widgets.
        </p>
        <ol>
          <li>
            <strong>Use the Code</strong>

            <ClipboardCopyInput
              id={"embeddable_script"}
              value={`${installScripTag} ${installDiv}`}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
        </ol>
        <div className={"alert alert-info"}>
          <ul style={alternativeListStyle}>
            <li>
              By using this alternative installation, you may face potential
              performance issues, so it’s recommended to limit its use and
              consider the recommended installation if possible.
            </li>
          </ul>
        </div>
      </InstallAccordion>
      <InstallAccordion
        title={"Experimental Installation (Fallback Method)"}
        defaultExpanded={true}
        expandable={true}
      >
        <p>
          This is a fallback method to use if your website theme employs an
          aggressive stylesheet that prevents the {widgetTypePrintable} from
          displaying properly. While this option may help embed the{" "}
          {widgetTypePrintable} effectively, it’s important to note that it may
          not offer the same visual integration as the recommended methods.
          Note: This method will embed your widget in the iframe on website.
        </p>
        <ol>
          <li>
            <strong>Include the Snippet</strong>
            <p>
              To implement this experimental installation, first copy and paste
              the following snippet into the{" "}
              <span className={"text-purple"}>&lt;head&gt;</span> section of
              your website. This step ensures the necessary scripts are loaded
              for the {widgetTypePrintable} functionality:
            </p>
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
          <li>
            <strong> Embed the Calendar</strong>
            <p>
              Next, insert the following HTML5 object in the location where you
              want the {widgetTypePrintable} to appear on your website:
            </p>
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDivIframe}
              placeholder={`${widgetTypePrintable} embeddable div`}
            />
            <div className={"alert alert-warning"}>
              <ul style={experimentalListStyle}>
                <li>
                  By using this experimental installation, you can overcome
                  display issues caused by your theme’s styles, but be mindful
                  that it may not provide the optimal user experience.
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </InstallAccordion>
    </div>
  );
};

export default StandardInstall;
