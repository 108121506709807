/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/03/2023.
 */

import React, { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import SidebarTab from "../../../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../../../component/SuccessAlert";
import PropTypes from "prop-types";
import ApiError from "../../../component/ApiError";
import { useCalendarsStore } from "../../../../../hooks/redux/calendars";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import { alertFeaturesCheck } from "../../../tools";
import LoadingIndicator from "../../../../groups/components/LoadingIndicator";
import { PLAN_NAMES } from "../../../../../consts";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import WidgetInstallModal from "../../../modals/WidgetInstallModal";
import classNames from "classnames";

const InstallTab = (props) => {
  const [showInstallModal, setShowInstallModal] = useState(false);

  const { widgetModel, widget } = props;
  const widgetType = widget.model.widget_type;
  const { calendars } = useCalendarsStore();
  const { filters } = useFiltersStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const featureCheck =
    widgetModel && widgetModel.integration && !widget.update
      ? alertFeaturesCheck
      : null;

  const publishIssues = featureCheck
    ? featureCheck(
        { ...widgetModel, available: 1 },
        currentOrganization,
        calendars,
        filters
      )
    : [];

  const publishRestrictionWarning =
    !widgetModel.available && publishIssues.length ? (
      <div className={"alert alert-danger mt-3 p-2"}>
        <i className={"text-danger m-r-5 material-icons icon-sm"}>warning</i>
        You cannot publish this {widgetType} widget because:
        <ul className={"pl-3"}>
          {publishIssues.map((i, idx) => (
            <li key={`cv-${idx}`}>{i}</li>
          ))}
        </ul>
        {props.recommendedPlan && (
          <span>
            Click here to upgrade to the minimum required plan{" "}
            <strong>{PLAN_NAMES[props.recommendedPlan]}</strong>.
          </span>
        )}
        <div className={"d-flex justify-content-center"}>
          <Link to={"/billing"}>
            <button className={"btn btn-success btn-sm"}>Upgrade now</button>
          </Link>
        </div>
      </div>
    ) : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeStatus = useCallback(
    (field) => (e) => {
      props.onUpdateStatus(!(widget.model.available * 1) ? 1 : 0);
    },
    [props.onUpdateStatus, widget]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onPublishClick = useCallback(
    (e) => {
      props.onUpdateStatus(1);
    },
    [props.onUpdateStatus, widget]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const onShowInstallModal = useCallback(() => {
    setShowInstallModal(true);
  }, [setShowInstallModal]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCloseInstallModal = useCallback(() => {
    setShowInstallModal(false);
  }, [setShowInstallModal]);

  const widgetTypeName = props.widgetTypeName.toLowerCase();

  const installModal = showInstallModal ? (
    <WidgetInstallModal
      widgetType={widgetType}
      uuid={widget.model.uuid}
      slug={widget.model.slug}
      show={showInstallModal}
      onClose={onCloseInstallModal}
      widgetTypePrintable={"event alerts"}
    />
  ) : null;
  const unpublishedWarning = !widgetModel.available ? (
    <div
      className={
        "alert alert-warning fade show d-flex align-items-center flex-column p-2"
      }
    >
      This widget is unpublished. Publish your widget first.
      <button className="btn btn-primary btn-sm m-2" onClick={onPublishClick}>
        Publish
      </button>
    </div>
  ) : null;

  const installButton =
    widgetModel.available && !publishIssues.length ? (
      <div className={"d-flex flex-column"}>
        <div className={"my-2"}>
          <button
            className={classNames("btn btn-primary w-100", {
              disabled: props.disabled || !widgetModel.available,
            })}
            disabled={props.disabled || !widgetModel.available}
            onClick={onShowInstallModal}
          >
            Install
          </button>
        </div>

        <div className={"mx-2"}>{unpublishedWarning}</div>
        {installModal}
      </div>
    ) : null;

  const publishCard = (
    <Accordion
      title={"Publish"}
      subtitle={`Your ${widgetTypeName} is ${
        widget.model.available * 1 === 0 ? "is not" : ""
      } published.`}
      keepExpanded={true}
    >
      <RadioInput
        fieldName={"available"}
        label={"Make this widget available to others"}
        onChange={onChangeStatus}
        checked={widgetModel.available}
        disabled={!widgetModel.available}
        tooltip={
          widget.model.available * 1 === 0
            ? {
                message: `In order to share your ${widgetTypeName} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle button.`,
              }
            : null
        }
      />

      {publishRestrictionWarning}
      {installButton}
      <SuccessAlert className={"p-2"}>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
    </Accordion>
  );

  const footer = !props.disabled ? (
    <div>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="install_tab" show={false} footer={footer}>
      {publishCard}
    </SidebarTab>
  );
};

InstallTab.defaultProps = {
  widgetTypeName: "Widget", // or Calendar
};

InstallTab.propTypes = {
  // form fields and errors
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object,

  errors: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  hasDomains: PropTypes.bool,

  disabled: PropTypes.bool,

  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string,
  shareableUrl: PropTypes.string,

  recommendedPlan: PropTypes.string,
};

export default InstallTab;
