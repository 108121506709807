/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import WidgetPreview from "./../component/WidgetSettings/WidgetPreview";
import SetupTab from "./GroupPage/SetupTab";
import DesignTab from "./../component/WidgetSettings/DesignTab";
import InstallTab from "../component/WidgetSettings/InstallTab";
import { store } from "../store";
import {
  calendarSettingsValidator,
  groupFeaturesCheck,
  groupSettingsValidator,
  updateJquery,
} from "../tools";
import { useGroupStore } from "../../../hooks/redux/group";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useUserStore } from "../../../hooks/redux/user";
import { useAppStore } from "../../../hooks/redux/app";
import { useDomainsStore } from "../../../hooks/redux/domains";
import WidgetPreviewHeader from "../component/WidgetSettings/WidgetPreviewHeader";
import Group from "../../groups/components/Group";
import { useBuilderStore } from "../../../hooks/redux/builder";
import IntegrationContext, {
  getStrategy,
} from "../../groups/strategies/IntegrationContext";
import { Store as NotificationComponent } from "react-notifications-component";
import NotificationPopup from "../component/NotificationPopup";
import NoItemsAlert from "./GroupPage/NoItemsAlert";
import IntegrationIssueNotification from "../component/IntegrationIssueNotification";
import { PLANS } from "../../../consts";
import IntegrationScopeIssueNotification from "../component/IntegrationScopeIssueNotification";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import useSetupChangesTrackerHook from "../hooks/useSetupChangesTrackerHook";

window.__DCE = window.__DCE || {};
window.__DCE.groups = window.__DCE.groups || {};

const GroupPage = (props) => {
  const uuid = props.match.params.uuid;
  const { app } = useAppStore();

  const { group, deleteGroup, fetchGroup, setGroup, updateGroup, groupItems } =
    useGroupStore();

  const { overwriteDesignProp } = useBuilderStore();

  const { integrations } = useIntegrationsStore();
  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { clearErrors } = useAppStore();
  const { domains } = useDomainsStore();

  const history = useHistory();
  const [state, setState] = useState({
    groupModel: null,
    errors: {},
  });
  const { trackChanges, setOriginValues, hasChanges } =
    useSetupChangesTrackerHook();

  const [savePointEventsSettings, setSavePointEventsSettings] = useState(null);

  // -------------------------------------------------------------------
  /**
   * Initialization of the Widget. It gets only UUID from query param.
   * 1. Load widget data from API
   * After these steps, widget is initialized and ready to render
   * @type {boolean}
   */

  const isWidgetInitialized = useMemo(() => {
    // model is loadedi
    // [model state is updated]
    return group.model &&
      group.model.slug &&
      group.model.integration &&
      state.groupModel
      ? true
      : false;
  }, [group.model, state.groupModel]);

  const strategy = useMemo(
    () =>
      getStrategy(isWidgetInitialized ? group.model.integration.type : null),
    [isWidgetInitialized, group.model]
  );

  useEffect(() => {
    (async () => {
      await fetchGroup(props.match.params.uuid);
    })();

    return () => {
      setGroup(null);
    };
  }, []);

  useEffect(() => {
    if (group.model) {
      console.log("restoring group model values");
      setState({
        ...state,
        groupModel: {
          ...group.model,
          integration_uuid: group.model.integration.uuid,
        },
      });
      setOriginValues({ ...group.model });
    }
  }, [group.model]);

  useEffect(() => {
    if (group.fetchSuccess) {
      if (!group.model.integration) {
        //history.push("/dashboard");
      }
    }

    if (group.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [group.fetchSuccess]);

  // -------------------------------------------------------------------

  useEffect(() => {
    if (group.updateSuccess) {
      setSavePointEventsSettings(
        Object.assign({}, group.model.widget_settings)
      );

      setTimeout(clearErrors, 4500);
      setOriginValues(group.model);
    }
  }, [group.updateSuccess]);

  useEffect(() => {
    if (isWidgetInitialized) {
      setSavePointEventsSettings(
        Object.assign({}, group.model.widget_settings)
      );
    }
  }, [isWidgetInitialized]);

  /**
   * track changes if design settings were updated
   */
  useEffect(() => {
    if (isWidgetInitialized && app.widgetSettingsUpdateTimestamp) {
      trackChanges("widget_settings", group.model.widget_settings);
    }
  }, [app.widgetSettingsUpdateTimestamp, isWidgetInitialized]);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const updatedModel = { ...state.groupModel };

    trackChanges(fieldName, value);
    updatedModel[fieldName] = value;

    setState({
      groupModel: updatedModel,
      errors: groupSettingsValidator(updatedModel),
    });
  };

  /**
   *
   */
  const onDeleteClick = async (e) => {
    e.preventDefault();

    if (group.fetch || group.delete) {
      return false;
    }

    if (
      window.confirm(
        "Do you really want to delete this widget? This action is irreversible."
      )
    ) {
      await deleteGroup(uuid);
    }

    if (store.getState().group.deleteSuccess) {
      history.push("/groups");
    }

    return false;
  };

  const validateSettings = (data) => {
    const issues = groupFeaturesCheck(data, currentOrganization);

    if (issues.length) {
      NotificationComponent.addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">
              You cannot publish this widget because:
            </p>
            <ul className={"notification-message"}>
              {issues.map((i, idx) => (
                <li key={`cv-${idx}`}>{i}</li>
              ))}
            </ul>
          </NotificationPopup>
        ),
        title: "You cannot publish this widget because:",
        //message: formatError(calendar.updateError).join(""),
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      return false;
    }

    return true;
  };

  const onUpdateStatus = useCallback(
    (value) => {
      const updatedModel = {
        ...state.groupModel,
      };

      updatedModel.available = value;

      const isConfigValid = validateSettings(updatedModel);
      const hasValidSettings = groupSettingsValidator(updatedModel);
      setState({ ...state, errors: hasValidSettings });

      if (!isConfigValid || Object.keys(hasValidSettings).length) {
        return false;
      }

      setState({
        ...state,
        groupModel: updatedModel,
      });

      _onSettingsSubmit(updatedModel);
    },
    [state.groupModel]
  );

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateFilterSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.groupModel,
      };
      updatedModel.filter_settings = {
        ...(updatedModel.filter_settings || {}),
      };
      updatedModel.filter_settings[field] = value;
      trackChanges("filter_settings", updatedModel.filter_settings);

      setState({
        groupModel: updatedModel,
        errors: groupSettingsValidator(updatedModel),
      });
    },
    [state.groupModel]
  );

  /**
   *
   */
  const onSettingsSubmit = async () => {
    console.log("on submit");
    const data = { ...state.groupModel };

    console.log(data);
    const hasValidSettings = groupSettingsValidator(data);
    setState({ ...state, errors: hasValidSettings });
    if (Object.keys(hasValidSettings).length) {
      return false;
    }
    _onSettingsSubmit(data);
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   * @private
   */
  const _onSettingsSubmit = async (data) => {
    // data.widget_settings = { ...group.model.widget_settings };

    // it overwrites the builder settings that are updated with redux but widget_settings contain other
    // settings that are not updated with redux
    const widgetSettings = {
      ...data.widget_settings,
    };

    data.widget_settings = {
      ...group.model.widget_settings,
    };

    // restore data.widget_settings using widgetSettings and omit 'design
    Object.keys(widgetSettings).forEach((key) => {
      if (key !== "design") {
        data.widget_settings[key] = widgetSettings[key];
      }
    });

    // if integration has changed, clear integration filters
    if (data.integration_uuid !== group.model.integration.uuid) {
      data.filter_settings = null;
    }

    await updateGroup(uuid, data);

    const groupStore = store.getState().group;

    if (
      groupStore.updateSuccess &&
      groupStore.model.integration.uuid !== group.model.integration.uuid
    ) {
      //const _strategy = getStrategy(groupStore.model.integration.type);
      //await fetchIntegrationFilters(groupStore.model.integration.uuid, _strategy.availableFilters);
      //await fetchGroupFilters(groupStore.model.uuid);
    }
  };

  const onUpdateWidgetSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.groupModel,
      };

      console.log(updatedModel);

      updatedModel.widget_settings = {
        ...(updatedModel.widget_settings || {}),
      };

      trackChanges(field, value);

      updatedModel.widget_settings[field] = value;

      setState({
        groupModel: updatedModel,
        errors: groupSettingsValidator(updatedModel),
      });
    },
    [state.groupModel]
  );

  /**
   *
   */
  const onSettingsRestore = () => {
    if (
      window.confirm(
        "This will remove all the changes you have done since the last SAVE. Are you sure?"
      )
    ) {
      overwriteDesignProp(savePointEventsSettings.design);
    }
  };

  const loading =
    group.update ||
    group.fetch ||
    group.delete ||
    !group.model ||
    !group.model.template ||
    !isWidgetInitialized;

  const settingsTabsOptions = isWidgetInitialized
    ? [
        {
          label: "Setup",
          id: "setup_tab",
          additionalClassName: "active show",
        },
        {
          label: "Design",
          id: "design_tab",
          additionalClassName: "",
        },
        {
          label: "Install",
          id: "install_tab",
          additionalClassName: "",
        },
      ]
    : [];

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const designTab = isWidgetInitialized ? (
    <DesignTab
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onRestore={onSettingsRestore}
      widget={group}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const setupTab = isWidgetInitialized ? (
    <SetupTab
      group={group}
      integrations={integrations.collection}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      overwriteDesignProp={overwriteDesignProp}
      user={user}
      groupModel={state.groupModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      onUpdateFilterSettings={onUpdateFilterSettings}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const installTab = isWidgetInitialized ? (
    <InstallTab
      widget={group}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      hasDomains={domains.collection.length ? true : false}
      widgetModel={state.groupModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      widgetTypeName={"Group"}
      shareableUrl={`${window.location.origin}/g/${group.model.slug}`}
      onUpdateStatus={onUpdateStatus}
      widgetType={"Group"}
      recommendedPlan={PLANS.starter}
      onUpdateWidgetSettings={onUpdateWidgetSettings}
    />
  ) : null;

  const groupSidebar = (
    <div
      className="col-lg-3 message-view chat-profile-view chat-sidebar"
      id="chat_sidebar"
    >
      <div className="chat-window video-window">
        <div className="fixed-header">
          <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
        </div>

        <div className="tab-content chat-contents">
          {setupTab}

          {designTab}

          {installTab}
        </div>
      </div>
    </div>
  );

  const integrationIssuesInfo = isWidgetInitialized ? (
    <IntegrationIssueNotification
      integration_uuid={group.model.integration.uuid}
    />
  ) : null;

  const syncScopeTurnedOffInfo = isWidgetInitialized ? (
    <IntegrationScopeIssueNotification
      integration_uuid={group.model.integration.uuid}
      mandatoryScopes={["groups"]}
    />
  ) : null;

  const noItemsToDisplayInfo =
    !integrationIssuesInfo &&
    !syncScopeTurnedOffInfo &&
    isWidgetInitialized &&
    groupItems.fetchSuccess &&
    !groupItems.collection.length ? (
      <NoItemsAlert group={group} />
    ) : null;

  const groupPreview = isWidgetInitialized ? (
    <React.Fragment>
      <WidgetPreview
        key={group.model.integration.type || new Date()}
        uuid={uuid}
        widget={group}
        widgetKind={"groups"}
      >
        <Group groupUUID={uuid} embedded={false} />
      </WidgetPreview>

      {integrationIssuesInfo}
      {syncScopeTurnedOffInfo}
      {noItemsToDisplayInfo}
    </React.Fragment>
  ) : null; /*<LoadingIndicator />*/

  return (
    <PageTemplate
      title={isWidgetInitialized ? group.model.name : "Loading..."}
      sidebarId="groups"
      icon={"group"}
      className={"page-wrapper-widget"}
      headerControls={[
        <WidgetPreviewHeader key="gpbc" onDelete={onDeleteClick} />,
      ]}
    >
      <IntegrationContext.Provider value={strategy}>
        <div className="chat-main-row">
          <div className="chat-main-wrapper">
            <div className="col-lg-9 message-view task-view">
              <div className="chat-window">{groupPreview}</div>
            </div>

            {groupSidebar}
          </div>
        </div>

        <div className="sidebar-overlay" data-reff="#chat_sidebar" />
      </IntegrationContext.Provider>
    </PageTemplate>
  );
};

export default GroupPage;
