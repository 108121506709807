export const headerTemplate = (settings) => {
  return `
  <!--[if mso]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:PixelsPerInch>96</o:PixelsPerInch>
      <o:AllowPNG/>
    </o:OfficeDocumentSettings>
  </xml><![endif]-->
  <style>
    * {
      box-sizing: border-box
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none
    }

    p {
      line-height: inherit
    }

    .desktop_hide, .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0;
      overflow: hidden
    }

    .image_block img + div {
      display: none
    }

    sub, sup {
      line-height: 0;
      font-size: 75%
    }

    @media (max-width: 620px) {
      .mobile_hide {
        display: none
      }

      .row-content {
        width: 100% !important
      }

      .stack .column {
        width: 100%;
        display: block
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0
      }

      .desktop_hide, .desktop_hide table {
        display: table !important;
        max-height: none !important
      }

      .row-1 .column-1 .block-1.image_block .alignment div {
        margin: 0 auto !important
      }
    }
    
    .mc-rss-item-img {
      width: 100% !important;
    }
  </style>
  <!--[if mso ]>
  <style>sup, sub {
    font-size: 100% !important;
  }

  sup {
    mso-text-raise: 10%
  }

  sub {
    mso-text-raise: -10%
  }</style> <![endif]-->`;
};
export const itemTemplate = (settings) => {
  const image = settings.showImage
    ? `<td class="column column-1" width="33.333333333333336%"
                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;border-right:8px solid transparent;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-bottom:0;border-left:0">
                  <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad" style="width:100%">
                        <div class="alignment" align="left" style="line-height:10px">
                          <div style="max-width:192px">
                            <a href="*|FEEDITEM:URL|*" target="_blank" style="outline:none" tabindex="-1">*|FEEDITEM:IMAGE|*</a></div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>`
    : "";

  const title = settings.showTitle
    ? `<table class="heading_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad" style="text-align:center;width:100%"><h1
                        style="margin:0;color:#555;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:23px;font-weight:700;letter-spacing:normal;line-height:120%;text-align:left;margin-top:0;margin-bottom:0;mso-line-height-alt:27.599999999999998px">
                        <span class="tinyMce-placeholder" style="word-break: break-word;">
                        <a href="*|FEEDITEM:URL|*"
                                                                                             target="_blank"
                                                                                             title="*|FEEDITEM:TITLE|*"
                                                                                             data-segment="1.2"
                                                                                             style="text-decoration: none; color: #0068A5;"
                                                                                             rel="noopener">*|FEEDITEM:TITLE|*</a></span>
                      </h1></td>
                    </tr>
                  </table>`
    : "";

  const description = settings.showDescription
    ? `<table class="paragraph_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                    <tr>
                      <td class="pad" style="padding-bottom:10px;padding-right:10px">
                        <div
                          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
                          <p style="margin:0">*|FEEDITEM:CONTENT|*</p></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const readMore = settings.showReadMore
    ? `<table class="paragraph_block block-4" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                    <tr>
                      <td class="pad">
                        <div
                          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
                          <p style="margin:0"><a href="*|FEEDITEM:URL|*" target="_blank" class title="Read more..."
                                                 style="text-decoration: underline; color: #0068A5;" data-segment="1.2"
                                                 rel="noopener">Read more...</a></p></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const date = settings.showDate
    ? `<table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                    <tr>
                      <td class="pad"
                          style="padding-bottom:10px;padding-right:10px;padding-top: 10px">
                        <div
                          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
                          <p style="margin:0"><strong>DATE:</strong> *|FEEDITEM:DATE:D,d M Y, g:i A|*</p></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  const location = settings.showLocation
    ? `<table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                    <tr>
                      <td class="pad"
                          style="padding-bottom:10px;padding-right:10px">
                        <div
                          style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left;mso-line-height-alt:16.8px">
                          <p style="margin:0"><strong>LOCATION:</strong> *|FEEDITEM:CATEGORIES|*</p></div>
                      </td>
                    </tr>
                  </table>`
    : "";

  return `  
<div class="body" style="margin: 0;padding: 0;background-color:${settings.bgColor};margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none">
<table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
       style="mso-table-lspace:0;mso-table-rspace:0;background-color:${settings.bgColor}">
  <tbody>
  <tr>
    <td>
      <table class="row row-1" align="center"
             width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
             style="mso-table-lspace:0;mso-table-rspace:0">
        <tbody>
        <tr>
          <td>
            <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                   role="presentation"
                   style="mso-table-lspace:0;mso-table-rspace:0;border-radius:0;color:#000;width:600px;margin:0 auto"
                   width="600">
              <tbody>
              <tr>
                ${image}
                <td class="column column-2" width="66.66666666666667%"
                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                  ${title}
                  ${date}
                  ${location}
                  ${description}
                  ${readMore}
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
             role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
        <tbody>
        <tr>
          <td>
            <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                   role="presentation"
                   style="mso-table-lspace:0;mso-table-rspace:0;border-radius:0;color:#000;width:600px;margin:0 auto"
                   width="600">
              <tbody>
              <tr>
                <td class="column column-1" width="100%"
                    style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:5px;padding-top:5px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                  <table class="divider_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0"
                         role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                      <td class="pad">
                        <div class="alignment" align="center">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                                 style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="divider_inner" style="font-size:1px;line-height:1px;border-top:1px solid #bbb">
                                <span style="word-break: break-word;">&#8202;</span></td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </tbody>
</table><!-- End --></div>`;
};
